<template>
    <div class="w-full">
		<SiteHeader />
		<section class="w-full">
			<div class="header-section mx-auto">
				<div class="container lg:flex">
					<div class="w-full lg:w-7/12 lg:flex lg:items-center">
						<h1 class="text-3xl md:text-5xl leading-snug md:leading-none text-white font-bold py-12 my-auto">
							THE FUTURE OF<br>RETAIL STARTS HERE
						</h1>
					</div>
					<div class="w-full lg:w-5/12 bg-white px-4 md:px-12 py-6 md:py-8">
						<h2 class="text-2xl md:text-3xl text-center leading-none text-gray-800 font-bold pb-6">
							Inquire Now!
						</h2>
						<div class="w-full">
							<InquiryForm />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-12">
			<div class="container py-8">
				<div class="w-full lg:w-10/12 text-center lg:mx-auto">
					<h2 class="text-2xl md:text-4xl text-center leading-snug text-gray-800 font-bold pb-4">
						From <span class="text-red-500">real-time inventory information</span> to the ability to <br class="hidden md:block"><span class="text-red-500">fulfill orders from any location</span>, become a true Omnichannel retailer with our RFID Supply Chain Solution.
					</h2>
					<!-- <div class="w-full text-base md:text-lg leading-normal text-gray-500 font-normal">
						From real-time inventory information to the ability to fulfill orders from any location, become a true Omnichannel retailer with our RFID Supply Chain Solution.
					</div> -->
				</div>
			</div>
		</section>

		<section class="w-full bg-black bg-solution pb-12">
			<div class="w-full text-center">
				<img src="/images/arrow-divider.png" class="mx-auto" alt="Arrow Divider" />
			</div>
			<div class="container py-8">
				<div class="w-full lg:w-10/12 text-lg md:text-2xl text-center leading-normal text-white font-normal md:mx-auto pb-12">
					With the ever-changing retail supply chain landscape, we bring you the latest technology to adapt to the new customer journey.
				</div>
				<h2 class="text-2xl md:text-4xl text-center leading-snug text-white font-bold pb-4">
					<span class="text-red-500">RFID Retail</span> Supply Chain Management 
				</h2>
				<div class="w-full lg:flex">
					<div class="w-full lg:w-6/12 text-base md:text-lg leading-normal text-white font-normal pb-4 lg:pb-0">
						An end-to-end solution that helps increase sales, reduce cost, enhance operations while leveraging on real-time customer data analytics, and omnichannel strategy.
					</div>
					<div class="w-full lg:w-6/12 text-base md:text-lg leading-normal text-white font-normal">
						<span class="text-red-500"><i>Our solution tells you:</i></span><br>
						What product you have, what product you need, where the product is, where it really needs to be and importantly where it does not need to be.
					</div>
				</div>

			</div>
		</section>

		<section>
			<div class="solution-img"></div>
		</section>

		<section class="w-full bg-gray-100 py-12">
			<div class="container py-8">
				<h2 class="text-2xl md:text-4xl text-center leading-snug text-gray-800 font-bold pb-8">
					<span class="text-red-500">The </span> Features
				</h2>
				<div class="grid items-start grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 w-full">
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon01.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Count inventory from each location in real-time</i>
						</div>
					</div>
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon02.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Ship inventory & receive shipments with 100% accuracy</i>
						</div>
					</div>
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon03.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Replenish your stocks automatically to reduce stockout</i>
						</div>
					</div>
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon04.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Fulfill orders from any location with Endless Aisle</i>
						</div>
					</div>
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon05.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Create decentralized virtual stockrooms from any store</i>
						</div>
					</div>
					<div class="w-full flex items-center bg-white rounded-lg shadow-xl mb-1 lg:mb-2 px-6 py-6">
						<div class="w-3/12">
							<img src="/images/feature-icon06.png" class="mx-auto" alt="Features Icon" />
						</div>
						<div class="w-8/12 text-base lg:text-lg leading-normal text-gray-400 font-normal pl-6">
							<i>Real-time and Actionable Data Analytics</i>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="w-full bg-white py-12">
			<div class="container py-8">
				<h2 class="text-2xl md:text-4xl text-center leading-snug text-gray-800 font-bold pb-8">
					<span class="text-red-500">What </span> Can Be Achieved
				</h2>
				<div class="grid items-start grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-5 w-full">
					<div class="w-11/12 md:w-full border-4 border-gray-800 rounded-lg px-3 py-6 mx-auto md:m-0">
						<div class="w-full result-container border-b-4 border-red-500">
							<div class="w-full text-center text-xl leading-snug font-normal text-gray-800 pb-4">
								Replenishment Improvement
							</div>
							<div class="w-full text-center text-3xl leading-snug font-bold text-red-500 pb-4">
								98.27%
							</div>
							<div class="w-full text-center text-lg leading-snug text-gray-400 font-normal pb-6">
								98% of sold units replenished from Sales, where stock is available in store. From 30% preRFID
							</div>
						</div>
					</div>

					<div class="w-11/12 md:w-full border-4 border-gray-800 rounded-lg px-3 py-6 mx-auto md:m-0">
						<div class="w-full result-container border-b-4 border-red-500">
							<div class="w-full text-center text-xl leading-snug font-normal text-gray-800 pb-4">
								Replenishment Efficiency
							</div>
							<div class="w-full text-center text-3xl leading-snug font-bold text-red-500 pb-4">
								20 mins
							</div>
							<div class="w-full text-center text-lg leading-snug text-gray-400 font-normal pb-6">
								20 minutes to replenish the average sold item. From 4.5 hours pre-RFID
							</div>
						</div>
					</div>

					<div class="w-11/12 md:w-full border-4 border-gray-800 rounded-lg px-3 py-6 mx-auto md:m-0">
						<div class="w-full result-container border-b-4 border-red-500">
							<div class="w-full text-center text-xl leading-snug font-normal text-gray-800 pb-4">
								Customer<br>Availability
							</div>
							<div class="w-full text-center text-3xl leading-snug font-bold text-red-500 pb-4">
								15 <span class="text-base">(1.36%)</span>
							</div>
							<div class="w-full text-center text-lg leading-snug text-gray-400 font-normal pb-6">
								Only an average of 15 sizes not available to the customer. From 1100 pre-RFID
							</div>
						</div>
					</div>

					<div class="w-11/12 md:w-full border-4 border-gray-800 rounded-lg px-3 py-6 mx-auto md:m-0">
						<div class="w-full result-container border-b-4 border-red-500">
							<div class="w-full text-center text-xl leading-snug font-normal text-gray-800 pb-4">
								Inventory<br>Accuracy
							</div>
							<div class="w-full text-center text-3xl leading-snug font-bold text-red-500 pb-4">
								97.7%
							</div>
							<div class="w-full text-center text-lg leading-snug text-gray-400 font-normal pb-6">
								Vs. 73.5% Pre-RFID
							</div>
						</div>
					</div>

					<div class="w-11/12 md:w-full border-4 border-gray-800 rounded-lg px-3 py-6 mx-auto md:m-0">
						<div class="w-full result-container border-b-4 border-red-500">
							<div class="w-full text-center text-xl leading-snug font-normal text-gray-800 pb-4">
								Reduced Out<br>of Stock
							</div>
							<div class="w-full text-center text-3xl leading-snug font-bold text-red-500 pb-4">
								5.6% <span class="text-base">(217 Sizes)</span>
							</div>
							<div class="w-full text-center text-lg leading-snug text-gray-400 font-normal pb-6">
								Reduced store Out of Stock. From 1730 sizes pre-RFID
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</section>

		<section class="w-full bg-gray-800 py-12">
			<div class="container py-8">
				<h2 class="text-2xl md:text-4xl text-center leading-snug text-white font-bold pb-3">
					Adlib International Sales
				</h2>
				<div class="w-full lg:w-8/12 text-center text-base leading-normal text-white font-normal mb-12 md:pb-0 lg:mx-auto">
					For over 30 years, we strive to help our partners future-proof their businesses through offering the latest technology in security and automation solutions in the Philippines.
				</div>
				<div class="w-4/12 md:w-2/12 border-b-4 border-red-500 mb-12 mx-auto"></div>
				<div class="w-full lg:w-8/12 text-center text-base leading-normal text-white font-normal md:pb-0 lg:mx-auto">
					4th Floor, CBC Corporate Center 724 Shaw Boulevard, 1552, Mandaluyong, Metro Manila
				</div>
				<div class="w-full lg:w-8/12 text-center text-base leading-normal text-white font-normal md:pb-0 lg:mx-auto">
					<br class="block md:hidden">
					<span>Contact Number : </span>
					<br class="block md:hidden">
					<a href="tel:0287212828" class="text-red-500">02 8721 2828</a>
					<span> / </span>
					<a href="tel:09178618263" class="text-red-500">0917 861 8263</a>
					<span class="invisible md:visible"> | </span>
					<br class="block md:hidden">
					<br class="block md:hidden">
					<span>Email : </span>
					<br class="block md:hidden">
					<a href="mailto:adlib@adlib.com.ph" class="text-red-500">adlib@adlib.com.ph</a>
					<br><br>
					<a href="https://www.facebook.com/adlib.international/" target="_blank">
						<img src="/images/fb-icon.png" class="mx-auto" alt="FB Icon" />
					</a>
				</div>
			</div>
		</section>
		

        <SiteFooter />
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import InquiryForm from '@/components/InquiryForm.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		InquiryForm,
		SiteFooter,
		CollapseTransition
	}
}
</script>
<style>
	.header-section {
		background-image: url('/images/header-bg-mobile.jpg');
		background-color: #000000;
        @apply bg-center;
        @apply bg-top;
		@apply bg-no-repeat;
		@apply bg-contain;
        @apply py-12;
	}
	
	.bg-solution {
		background-image: url('/images/solution-bg-network.png');
        @apply bg-center;
        @apply bg-bottom;
        @apply bg-no-repeat;
        @apply bg-cover;
	}

	.solution-img {
		background-image: url('/images/solution-bg02-mobile.jpg');
		@apply bg-center;
		@apply bg-center;
		@apply bg-no-repeat;
		@apply bg-cover;
		width: 100%;
		height: 320px;
	}

	.bg-gray-800 {
		background-color: #292929 !important;
	}

	@media (min-width: 768px) {
		.header-section {
			background-image: url('/images/header-bg.jpg');
			@apply bg-center;
			@apply bg-bottom;
			@apply bg-cover;
		}
		
		.solution-img {
			background-image: url('/images/solution-bg02-dekstop.jpg');
			height: 420px;
		}

		.result-container {
			min-height: 284px;
		}
    }

	@media (min-width: 1024px) {
		
    }

	@media (min-width: 1280px) {
		.container {
			max-width: 1280px !important;
		}
	}

	@media (min-width: 1440px) {
		
    }
</style>